import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { setObjectTypeId } from '../objectTypes';

export type ActiveGroupType = 'primary' | string;
export type UseType = 'first' | 'loop';
export type DefinedBy = 'all' | 'hubspot' | 'user';

export type PropertiesUiSliceState = {
  searchValue: string;
  useType: UseType;
  isFormatted: boolean;
  definedBy: DefinedBy;
  groupName: string | undefined;
  activePropertyGroup: ActiveGroupType; // Can be primary or the name of the association list group name
};

const initialState: PropertiesUiSliceState = {
  searchValue: '',
  useType: 'first',
  isFormatted: false,
  definedBy: 'all',
  groupName: undefined,
  activePropertyGroup: 'primary',
};

export const propertiesUiSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setPropertiesUi(state, action: PayloadAction<Partial<PropertiesUiSliceState>>) {
      return { ...state, ...action.payload };
    },
    resetPropertiesUi(state, action: PayloadAction<void>) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setObjectTypeId, (state) => {
      return { ...state, activePropertyGroup: 'primary', groupName: undefined };
    });
  },
});

export const { setPropertiesUi, resetPropertiesUi } = propertiesUiSlice.actions;

export const selectSearchValue = (state: RootState) => state.properties.searchValue;
export const selectUseType = (state: RootState) => state.properties.useType;
export const selectIsFormatted = (state: RootState) => state.properties.isFormatted;
export const selectDefinedBy = (state: RootState) => state.properties.definedBy;
export const selectGroupName = (state: RootState) => state.properties.groupName;
export const selectActivePropertyGroup = (state: RootState) => state.properties.activePropertyGroup;

export default propertiesUiSlice.reducer;
