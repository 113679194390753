import { useDispatch, useSelector } from 'react-redux';
import {
  setCreatorMode as _setCreatorMode,
  setDocumentIsGenerating as _setDocumentIsGenerating,
  setDocumentIsRefreshing as _setDocumentIsRefreshing,
  resetDocumentUi as _resetDocumentUi,
  selectCreatorMode,
  selectIsGenerating,
  selectIsRefreshing,
} from '../documentSlice';
import { useCallback, useMemo } from 'react';

export interface UseDocumentUiReturnType {
  creatorMode: boolean;
  setCreatorMode: (value: boolean) => void;

  // Document generation and refresh states are used to show loading indicators depending on the kind of request made.
  documentIsGenerating: boolean;
  setDocumentIsGenerating: (value: boolean) => void;

  documentIsRefreshing: boolean;
  setDocumentIsRefreshing: (value: boolean) => void;

  resetDocumentUi: () => void;
}

export default function useDocumentUi() {
  const creatorMode = useSelector(selectCreatorMode);
  const documentIsGenerating = useSelector(selectIsGenerating);
  const documentIsRefreshing = useSelector(selectIsRefreshing);

  const dispatch = useDispatch();

  const setCreatorMode = useCallback(
    (value: boolean) => {
      dispatch(_setCreatorMode(value));
    },
    [dispatch],
  );

  const setDocumentIsGenerating = useCallback(
    (value: boolean) => {
      dispatch(_setDocumentIsGenerating(value));
    },
    [dispatch],
  );

  const setDocumentIsRefreshing = useCallback(
    (value: boolean) => {
      dispatch(_setDocumentIsRefreshing(value));
    },
    [dispatch],
  );

  const resetDocumentUi = useCallback(() => {
    dispatch(_resetDocumentUi());
  }, [dispatch]);

  return useMemo(
    () => ({
      creatorMode,
      setCreatorMode,
      documentIsGenerating,
      setDocumentIsGenerating,
      documentIsRefreshing,
      setDocumentIsRefreshing,
      resetDocumentUi,
    }),
    [
      creatorMode,
      setCreatorMode,
      documentIsGenerating,
      setDocumentIsGenerating,
      documentIsRefreshing,
      setDocumentIsRefreshing,
      resetDocumentUi,
    ],
  );
}
