import { useMemo } from 'react';
import { useObjectTypes } from '../../objectTypes';
import { CustomProperty, CustomPropertyGroup, useGetPropertiesQuery } from '../propertiesApi';

export interface useGetPropertiesReturnType {
  propertiesMeta: ReturnType<typeof useGetPropertiesQuery>;
  properties: CustomProperty[];
  groups: CustomPropertyGroup[];
}

export function useGetProperties(): useGetPropertiesReturnType {
  const { objectTypeId } = useObjectTypes();

  const propertiesMeta = useGetPropertiesQuery(objectTypeId || '', { skip: !objectTypeId });

  const properties = useMemo(() => propertiesMeta?.data?.properties || [], [propertiesMeta]);
  const groups = useMemo(() => propertiesMeta?.data?.groups || [], [propertiesMeta]);

  return useMemo(() => ({ propertiesMeta, properties, groups }), [propertiesMeta, properties, groups]);
}
