import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

// Views
import DashboardView from './views/Dashboard';
import ApiKeyView from './views/ApiKey';
import Unauthorized from './views/Unauthorized';
import Login from './views/Login';
import TemplatesPage from './views/Templates';
import Authorize from './views/Authorize';
import PropertiesPage from './views/Properties';

import { ProtectedRoute } from './components/ProtectedRoute';

function App() {
  const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/unauthorized', element: <Unauthorized /> },
    {
      path: '/dashboard',
      element: <ProtectedRoute component={<DashboardView />} />,
    },
    // Templates page is different from the templates view as templates is contained in a page and as a modal.
    {
      path: '/templates',
      element: <ProtectedRoute component={<TemplatesPage />} />,
    },
    {
      path: '/properties',
      element: <ProtectedRoute component={<PropertiesPage />} />,
    },
    {
      path: '/api-key',
      element: <ProtectedRoute component={<ApiKeyView />} />,
    },
    {
      path: '/authorize',
      element: <Authorize />,
    },
    {
      path: '*',
      element: <Navigate to={'/'} />,
    },
  ]);

  return (
    <PrimeReactProvider>
      <RouterProvider router={router} />
    </PrimeReactProvider>
  );
}

export default App;
