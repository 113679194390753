import { useDispatch, useSelector } from 'react-redux';
import { ExpandedObjectInterface } from '../objectsApi';
import { selectObjectId, setObjectId as _setobjectId, selectAllObjects } from '../objectsSlice';
import { useCallback, useMemo } from 'react';

export interface UseObjectsReturnType {
  selectedObject: ExpandedObjectInterface | undefined;
  allObjects: ExpandedObjectInterface[];
  objectId: string | null;

  setObjectId(objectId: string): void;
}

export default function useObjects(): UseObjectsReturnType {
  const objectId = useSelector(selectObjectId);
  const allObjects = useSelector((state: any) => selectAllObjects(state));

  const dispatch = useDispatch();

  const selectedObject = useMemo(() => {
    return allObjects?.find((o) => o.id === objectId);
  }, [allObjects, objectId]);

  const setObjectId = useCallback(
    (objectId: string) => {
      dispatch(_setobjectId(objectId));
    },
    [dispatch],
  );

  return {
    selectedObject,
    allObjects,
    objectId,
    setObjectId,
  };
}
