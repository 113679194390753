import { Skeleton } from 'primereact/skeleton';
import { getPageDimensions } from '../../../utils';

const DocumentViewerSkeleton = () => {
  const { width, height } = getPageDimensions({ width: '500px', height: '647px', ppi: 72 });

  const ROWS = 5;

  return (
    <div className='page-loader flex flex-col justify-start h-screen items-center relative overflow-y-auto w-full'>
      <div style={{ width, height }} className='bg-white flex flex-col p-12 gap-5 mt-2'>
        <div className='flex justify-between'>
          <div className='flex flex-col items-end gap-3'>
            <Skeleton width='10rem' className='mb-2'></Skeleton>
            <Skeleton width='5rem' className='mb-2'></Skeleton>
          </div>

          <div className='flex flex-col items-end gap-3'>
            <Skeleton width='10rem' height='4rem'></Skeleton>
            <Skeleton width='10rem' className='mb-2'></Skeleton>
          </div>
        </div>

        <Skeleton className='mb-2'></Skeleton>

        <Skeleton height='2rem' className='mb-2'></Skeleton>
        <div className='flex flex-col gap-3'>
          {Array.from({ length: ROWS }).map((k, i) => (
            <div className='flex gap-6' key={i}>
              <Skeleton width='55%' className='mb-2'></Skeleton>
              <Skeleton width='15%' className='mb-2'></Skeleton>
              <Skeleton width='15%' className='mb-2'></Skeleton>
              <Skeleton width='15%' className='mb-2'></Skeleton>
            </div>
          ))}
        </div>
        <Skeleton width='10rem' className='mb-2'></Skeleton>
        <Skeleton height='2rem' className='mb-2'></Skeleton>
      </div>

      <div className='flex relative w-full justify-center'>
        <div className='absolute bottom-10 block'>
          <Skeleton
            width='100%'
            height='3rem'
            className='p-4 flex items-center bg-primary-500 border-2 border-primary-600'
          >
            <p className='text-white'>Creating document</p>
          </Skeleton>
        </div>
      </div>
    </div>
  );
};

export default DocumentViewerSkeleton;
