import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiKey, useAuth } from '../../features/auth';
import { ProgressBar } from 'primereact/progressbar';
import { Image } from 'primereact/image';
import { useLazyCheckTokenValidityQuery } from '../../features/auth/hubspotApi';
import { Skeleton } from 'primereact/skeleton';

import './style.css';

const UNAUTHORIZED_REDIRECT_PATH = '/unauthorized';

type LoginStatus = {
  label: string;
  value: number;
};

const Login = () => {
  const [loadingProgress, setLoadingProgress] = useState<LoginStatus>({ label: 'idle', value: 0 });

  const isLoggingInRef = useRef<boolean>(false);

  const { getUser, setToken, getToken } = useAuth();
  const { checkValidity } = useApiKey();
  const [checkTokenValidity] = useLazyCheckTokenValidityQuery();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { redirectTo } = state || {};

  // Navigation functions
  const redirectLogin = useCallback(() => {
    navigate(redirectTo || '/templates');
  }, [navigate, redirectTo]);

  const redirectApiKey = useCallback(() => {
    navigate('/api-key', { state: { redirectTo: redirectTo || '/templates' } });
  }, [navigate, redirectTo]);

  const redirectAuthorize = useCallback(() => {
    return navigate('/authorize');
  }, [navigate]);

  const redirectNoAuth = useCallback(() => {
    navigate(UNAUTHORIZED_REDIRECT_PATH);
  }, [navigate]);

  // Template method to perform login actions
  const doLogin = useCallback(
    async (token: string) => {
      setLoadingProgress({ label: 'started', value: 30 });

      try {
        setLoadingProgress({ label: 'getting user', value: 40 });

        Promise.all([getUser(token), checkTokenValidity(), checkValidity()])
          .then(([userResponse, tokenValidity, apiKeyValidity]) => {
            // Get User
            if (!userResponse) return redirectNoAuth();
            // Validate Scopes
            if (tokenValidity?.isError || tokenValidity?.data?.error) return redirectAuthorize();
            // Validate the API Key
            if (apiKeyValidity?.isError || !apiKeyValidity?.data?.isValid) return redirectApiKey();

            redirectLogin();
          })
          .catch((error) => {
            console.error(error);
            return redirectNoAuth();
          });

        // Target loading progress value
        setLoadingProgress({ label: 'success', value: 99 });
      } catch (error) {
        console.error(error);
      }
    },
    [
      getUser,
      setLoadingProgress,
      checkValidity,
      checkTokenValidity,
      redirectLogin,
      redirectNoAuth,
      redirectApiKey,
      redirectAuthorize,
    ],
  );

  // Initiates the login process, by getting the token from the URL or local storage
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    // get the token from the url or session storage
    const urlToken = search.get('session_token');
    const storedToken = getToken();
    const token = urlToken ?? storedToken;

    // if the token is not a string, redirect to unauthorized
    if (typeof token !== 'string') return redirectNoAuth();

    // If the new token does not match the stored token, update the stored token
    if (token !== storedToken) setToken(token);

    if (!isLoggingInRef.current) {
      isLoggingInRef.current = true;
      doLogin(token);
    }
  }, [doLogin, setToken, setLoadingProgress, redirectNoAuth, getToken]);

  return (
    <div
      className='w-full h-screen flex justify-center items-center bg-primary-50'
      style={{ borderRadius: '20%', boxShadow: 'inset 0 0 100px 100px rgba(255, 255, 255, 1)' }}
    >
      <div className='text-center flex flex-col items-center gap-8' style={{ minWidth: '300px' }}>
        <div className='flex justify-center items-center'>
          <Image src='./images/logo.svg' width='180px' />
        </div>

        <ProgressBar
          value={loadingProgress?.value}
          showValue={false}
          style={{ height: '6px', width: '160px' }}
          pt={{
            root: {
              style: {
                backgroundColor: 'white',
              },
            },
            value: {
              className: 'p-skeleton',
            },
          }}
        ></ProgressBar>
        {/* Needed to bring in skeleton styles */}
        <Skeleton height='0px' />
      </div>
    </div>
  );
};

export default Login;
