import { useCallback, useEffect, useMemo } from 'react';

import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { ObjectTypeDropdown, useObjectTypes } from '../../../objectTypes';
import ObjectsTable from './table/ObjectTable';
import { useObjectsUi } from '../../hooks';
import { useGetObjectsPage } from '../../hooks/useGetObjectsPage';
import ControlledSearchButtonInput from '../../../../components/ControlledSearchButtonInput';
import { useDebounce } from 'primereact/hooks';

// See Paginator component for the shape of the page data.
type PageDataType = { first: number; rows: number; page: number; pageCount: number };

export default function ObjectList() {
  const { selectedObjectType, objectTypeId } = useObjectTypes();
  const { objectParams, setObjectParams } = useObjectsUi();
  const { objectsPageData, objectsPageMeta } = useGetObjectsPage();

  const isFetching = useMemo(() => objectsPageMeta.isFetching, [objectsPageMeta.isFetching]);

  const [searchValue, debouncedSearchValue, setDebouncedSearchValue] = useDebounce<string | undefined>(undefined, 500);

  // Called when user selects another page.
  const handlePageChange = useCallback(
    (pageData: PageDataType) => {
      if (!objectParams || !objectTypeId) return;
      setObjectParams({ after: pageData.first, limit: pageData.rows });
    },
    [objectParams, setObjectParams, objectTypeId],
  );

  useEffect(() => {
    setObjectParams({ searchValue: debouncedSearchValue, after: 0 });
  }, [debouncedSearchValue, setObjectParams]);

  const reload = useCallback(() => {
    objectsPageMeta.refetch();
  }, [objectsPageMeta]);

  return (
    <div className='flex flex-col gap-4 h-full'>
      {/* HEADER */}
      <div className='grid grid-cols-3 py-2 px-1'>
        {/* LEFT */}
        <div className='flex justify-start items-center gap-3 '>
          <ObjectTypeDropdown filter={true} />
          <ControlledSearchButtonInput
            value={searchValue || ''}
            handleValueChange={(e) => setDebouncedSearchValue(e)}
          />
        </div>

        {/* CENTER */}
        <div className='flex justify-center items-center'>
          <h4 className='text-2xl font-medium text-slate-600'>{`Select a ${
            selectedObjectType?.singularLabel || 'Object'
          }`}</h4>
        </div>

        {/* RIGHT */}
        <div className='flex justify-end items-center gap-3 '>
          <Button size='small' onClick={reload} disabled={isFetching} icon='pi pi-refresh' outlined severity='help' />
        </div>
      </div>

      {/* CONTENT */}
      <div className='flex-1 flex flex-col gap-5 border items-center justify-between h-full w-full bg-slate-50 overflow-auto'>
        <ObjectsTable />

        <Paginator
          first={Number(objectParams.after) || 0}
          rows={objectParams.limit}
          totalRecords={objectsPageData?.total || 0}
          onPageChange={(pageData: PageDataType) => handlePageChange(pageData)}
          rowsPerPageOptions={[25, 40, 50]}
        />
      </div>
    </div>
  );
}
