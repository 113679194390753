import { Badge } from 'primereact/badge';
import * as dateFns from 'date-fns';

import type { Template } from '../../../templatesApi';
import { Tooltip } from 'primereact/tooltip';

interface Props {
  template: Template;
  onSelect: (template: Template) => void;
}

const TemplateListGridItem = ({ template, onSelect }: Props) => {
  const handleSelect = () => onSelect(template);

  const activeBadgeId = `active-badge-${template.id}`;

  const { isActive } = template;
  return (
    <>
      <div
        onClick={handleSelect}
        className='border border-slate-200 flex flex-col justify-stretch  surface-card rounded-lg cursor-pointer bg-white overflow-hidden hover:shadow-md'
      >
        <div className='flex flex-col items-center gap-3 p-2 bg-slate-300 grow-1 basis-full pb-0'>
          <div
            className='w-full rounded-sm'
            style={{
              backgroundImage: `url(${template?.thumbnail?.url})`,
              height: '170px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
        </div>
        <div className='flex items-start justify-start p-3 basis-full z-10 border-t border-t-slate-200 gap-2'>
          <Badge
            id={activeBadgeId}
            style={{
              width: '0.6rem',
              height: '0.6rem',
              marginTop: '0.75rem',
              background: isActive ? 'rgb(0, 155, 255)' : 'lightgray',
            }}
            // severity={isActive ? undefined : 'secondary'}
            data-pr-tooltip={isActive ? 'Active' : 'Inactive'}
            data-pr-position='right'
            data-pr-showdelay={300}
          />
          <div className='text-lg font-bold'>
            <div className='mb-2'>{template.name}</div>
            <div className='text-sm text-neutral-400'>
              {template?.updatedAt && (
                <>
                  Last updated {dateFns.formatDistance(new Date(template?.updatedAt), new Date(), { addSuffix: true })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Tooltip target={`#${activeBadgeId}`} />
    </>
  );
};

export default TemplateListGridItem;
