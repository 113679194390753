import { apiSlice } from '../../api';
import { transformSingleResponse } from '../../api/utils';

export type CustomProperty = {
  key: string;
  name: string;
  label: string;
  isCustom: boolean;
  type: string;
  relatedObjectType?: string;
  groupId?: string;
  groupName?: string;
  relatedObjectProperties?: CustomProperty[];
  fieldType?: string;
  parentName?: string;
};

export interface CustomPropertyGroup {
  id: string;
  name: string;
  order: number;
  archived: boolean;
}

export interface GetPropertiesResponse {
  properties: CustomProperty[];
  groups: CustomPropertyGroup[];
}

export const propertiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<GetPropertiesResponse, string>({
      query: (objectType) => `/properties/${objectType}`,
      providesTags: [{ id: 'List', type: 'Properties' }],
      transformResponse: transformSingleResponse<GetPropertiesResponse>,
    }),
  }),
});

export const { useGetPropertiesQuery, useLazyGetPropertiesQuery } = propertiesApi;
