import React, { useEffect, useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, LoadError } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { useDocumentViewer } from '../hooks/useDocumentViewer';
import DocumentViewerControls from './DocumentViewerControls';
import EmptyState from './EmptyState';
import DocumentViewerSkeleton from './DocumentViewerSkeleton';
import { DocumentViewerProvider } from '../contexts';
import { Message } from 'primereact/message';
import { useCreateDocumentMutation } from '../documentsApi';
import { useDocument } from '../hooks/useDocument';
import LoadErrorMessage from './LoadErrorMessage';

const DocumentViewer = () => {
  const { toolbarInstance } = useDocumentViewer();

  const { paramsChanged, currentParams, documentUrl, setDocumentUrl, setPrevParams } = useDocument();

  const [loadError, setLoadError] = useState<LoadError | null>(null);

  const [createDocument, documentMeta] = useCreateDocumentMutation({ fixedCacheKey: 'document' });

  // Looks out for document param changes. If there are changes, it creates a new document, else it does nothing.
  useEffect(() => {
    if (!paramsChanged || !currentParams) return;
    createDocument(currentParams);
    setPrevParams(currentParams);
  }, [paramsChanged, currentParams, createDocument, setPrevParams]);

  // Set the document url when the document is created
  useEffect(() => {
    if (documentMeta.data) {
      setDocumentUrl(documentMeta.data.url);
    }
  }, [documentMeta, setDocumentUrl]);

  return (
    <div id='document-viewer' className='relative' style={{ height: '100%', backgroundColor: '#f4f7ff' }}>
      <div style={{ wordBreak: 'break-word' }} className='h-full'>
        {documentMeta.isLoading ? (
          <DocumentViewerSkeleton />
        ) : documentMeta.error ? (
          <div className='flex justify-center items-center h-full'>
            <Message severity='error' text={'Error'} />
          </div>
        ) : documentUrl ? (
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            <Viewer
              fileUrl={documentUrl}
              defaultScale={SpecialZoomLevel.PageFit}
              plugins={[toolbarInstance]}
              renderError={(error: LoadError) => {
                setLoadError(error);
                return <LoadErrorMessage error={error} />;
              }}
              renderLoader={() => <DocumentViewerSkeleton />}
              pageLayout={{
                transformSize: ({ size }) => ({
                  height: size.height + 10,
                  width: size.width + 10,
                }),
              }}
            />
            {!loadError && currentParams && <DocumentViewerControls />}
          </Worker>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

const MemoizedViewer = React.memo(DocumentViewer);

export default function DocumentViewerWrapper() {
  return (
    <DocumentViewerProvider>
      <MemoizedViewer />
    </DocumentViewerProvider>
  );
}
