import { apiSlice } from '../../api';
import type { ListQueryParams, ListResponse } from '../../api/types';
import { transformListResponse, transformSingleResponse } from '../../api/utils';

const OBJECTS_URL = 'objects';

// ExpandedObjectInterface is copied from the server and expands HS's object with additional properties provided by its object type schema.
export interface ExpandedObjectInterface {
  archived: boolean;
  createdAt: string;
  id: string; // The unique object ID
  properties: {
    [key: string]: any;
  };
  updatedAt: string;
  primaryDisplayProperty: string;
  secondaryDisplayProperties: {
    [key: string]: string | null;
  };
  objectTypeLabels: {
    plural: string; // e.g. 'Contacts', 'Companies', 'Deals'
    singular: string; // e.g. 'Contact', 'Company', 'Deal'
  };
  objectTypeName: string; // e.g. 'CONTACT', 'COMPANY', 'DEAL'
  objectTypeId: string; // e.g. '0-1', '0-2', '0-3'
  searchableProperties: string[]; // a list of properties that be used to use HS's search functionality
}

export interface ObjectSingleQueryParams {
  properties?: string[];
}

export interface ObjectSingleQueryArgs {
  objectType: string;
  objectId: string;
  queryParams?: ObjectSingleQueryParams;
}

export type ObjectListQueryResponse = ListResponse<ExpandedObjectInterface>;

export const objectsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getObject: builder.query<ExpandedObjectInterface, ObjectSingleQueryArgs>({
      query: (args) => ({
        url: `/${OBJECTS_URL}/single/${args.objectType}/${args.objectId}`,
        params: args.queryParams,
      }),
      providesTags: ['Object'],
      transformResponse: transformSingleResponse,
    }),

    getDefaultObject: builder.query<ExpandedObjectInterface, void>({
      query: () => `/${OBJECTS_URL}/single`,
      providesTags: ['Object'],
      transformResponse: transformSingleResponse,
    }),

    getObjects: builder.query<ObjectListQueryResponse, { objectType: string; queryParams: ListQueryParams }>({
      query: (args) => ({
        url: `/${OBJECTS_URL}/list/${args.objectType}`,
        params: args.queryParams,
      }),
      transformResponse: transformListResponse,
      providesTags: (response) =>
        response
          ? [
              { id: 'LIST', type: 'Objects' as const },
              ...response?.data?.map(({ id }) => ({ id, type: 'Objects' as const })),
            ]
          : [{ id: 'LIST', type: 'Objects' as 'Objects' }],
    }),
  }),
});

export const {
  useGetObjectQuery,
  useGetDefaultObjectQuery,
  useLazyGetObjectQuery,
  useLazyGetDefaultObjectQuery,
  useGetObjectsQuery,
  useLazyGetObjectsQuery,
} = objectsApi;
