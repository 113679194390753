import { useMemo } from 'react';
import { TemplateListResponse, useListTemplatesQuery } from '../templatesApi';
import useTemplateUI from './useTemplatesUI';

export interface UseGetTemplatesPageReturnType {
  templatesPageData: TemplateListResponse | undefined;
  templatesPageMeta: ReturnType<typeof useListTemplatesQuery>;
}

export function useGetTemplatesPage(): UseGetTemplatesPageReturnType {
  const { templateParams } = useTemplateUI();

  const templatesPageMeta = useListTemplatesQuery(templateParams, { skip: !templateParams });

  const templatesPageData = useMemo(() => {
    return templatesPageMeta.data || undefined;
  }, [templatesPageMeta]);

  return useMemo(() => ({ templatesPageData, templatesPageMeta }), [templatesPageData, templatesPageMeta]);
}
