import { ExpandedObjectInterface } from '../objectsApi';

export function startsWithVowel(word: string) {
  return RegExp('^[aeiou]', 'i').test(word);
}

export function renderContactTitle(object: ExpandedObjectInterface) {
  const { primaryDisplayProperty, secondaryDisplayProperties = {} } = object || {};
  const { lastname, email } = secondaryDisplayProperties;

  let result = '---';

  if (primaryDisplayProperty || lastname) {
    result = [primaryDisplayProperty, lastname].join(' ');
  } else if (email) {
    result = email;
  }

  return result;
}
