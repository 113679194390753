import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { documentApi } from './documentsApi';

export interface PrevDocumentParams {
  objectId: string | undefined;
  objectTypeId: string | undefined;
  templateId: string | undefined;
}

export interface DocumentSliceState {
  data: {
    // The URL of the document to be displayed. Used to prevent unnecessary reloads.
    url: string | undefined;
    // Previous params used to see if the document needs to be reloaded. If the params are the same.
    prevParams: PrevDocumentParams | undefined;
  };
  ui: {
    creatorMode: boolean;
    isGenerating: boolean;
    isRefreshing: boolean;
  };
}
const initialState: DocumentSliceState = {
  data: {
    url: undefined,
    prevParams: undefined,
  },
  ui: {
    creatorMode: false,
    isGenerating: false,
    isRefreshing: false,
  },
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    // Data reducers
    setDocumentUrl(state: DocumentSliceState, action: PayloadAction<string | undefined>) {
      if (state.data.url === action.payload) return;
      state.data.url = action.payload;
    },
    setPrevParams(state: DocumentSliceState, action: PayloadAction<DocumentSliceState['data']['prevParams']>) {
      state.data.prevParams = action.payload;
    },
    resetPrevParams(state: DocumentSliceState, action: PayloadAction<void>) {
      state.data.prevParams = initialState.data.prevParams;
    },
    resetDocumentData(state: DocumentSliceState, action: PayloadAction<void>) {
      state.data = initialState.data;
    },

    // UI reducers
    setCreatorMode(state: DocumentSliceState, action: PayloadAction<boolean>) {
      state.ui.creatorMode = action.payload;
    },
    setDocumentIsGenerating(state: DocumentSliceState, action: PayloadAction<boolean>) {
      state.ui.isGenerating = action.payload;
    },
    setDocumentIsRefreshing(state: DocumentSliceState, action: PayloadAction<boolean>) {
      state.ui.isRefreshing = action.payload;
    },
    resetDocumentUi(state: DocumentSliceState, action: PayloadAction<void>) {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // addMatchers for updating the UI state when the document is being generated or refreshed
    builder.addMatcher(documentApi.endpoints.createDocument.matchPending, (state: DocumentSliceState, action) => {
      const { isLive } = action.meta.arg.originalArgs;

      if (isLive) {
        state.ui.isGenerating = true;
      } else {
        state.ui.isRefreshing = true;
      }
    });
    builder.addMatcher(documentApi.endpoints.createDocument.matchFulfilled, (state: DocumentSliceState, action) => {
      const { isLive } = action.meta.arg.originalArgs;

      if (isLive) {
        state.ui.isGenerating = false;
      } else {
        state.ui.isRefreshing = false;
      }
    });
  },
});

export const {
  setDocumentUrl,
  resetDocumentData,
  resetPrevParams,
  setPrevParams,
  setCreatorMode,
  setDocumentIsGenerating,
  setDocumentIsRefreshing,
  resetDocumentUi,
} = documentSlice.actions;

export const selectDocumentUrl = (state: RootState) => state.document.data.url;
export const selectPrevParams = (state: RootState) => state.document.data.prevParams;
export const selectCreatorMode = (state: RootState) => state.document.ui.creatorMode;
export const selectIsGenerating = (state: RootState) => state.document.ui.isGenerating;
export const selectIsRefreshing = (state: RootState) => state.document.ui.isRefreshing;

export default documentSlice.reducer;
