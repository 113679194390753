import { Button } from 'primereact/button';
import { useGetProperties } from '../../hooks';

export default function RefetchPropertiesButton() {
  const { propertiesMeta } = useGetProperties();
  return (
    <Button
      size='small'
      onClick={() => propertiesMeta.refetch()}
      disabled={propertiesMeta.isFetching}
      icon='pi pi-refresh'
      outlined
      severity='secondary'
    />
  );
}
