import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setObjectModalVisibility as _setObjectModalVisibility,
  setObjectParams as _setObjectParams,
  resetObjectParams as _resetObjectParams,
  selectObjectModalVisibility,
  selectObjectParams,
} from '../objectsSlice';

import type { ListQueryParams } from '../../../api/types';

interface UseObjectsUiReturnValue {
  setObjectModalVisibility: (value: boolean) => void;
  objectModalVisibility: boolean;
  objectParams: ListQueryParams;

  setObjectParams(params: ListQueryParams): void;
  resetObjectParams(): void;
}

export function useObjectsUi(): UseObjectsUiReturnValue {
  const dispatch = useDispatch();
  const objectModalVisibility = useSelector(selectObjectModalVisibility);
  const objectParams = useSelector(selectObjectParams);

  const setObjectModalVisibility = useCallback(
    (value: boolean) => {
      dispatch(_setObjectModalVisibility(value));
    },
    [dispatch],
  );

  const setObjectParams = useCallback(
    (params: ListQueryParams) => {
      dispatch(_setObjectParams(params));
    },
    [dispatch],
  );

  const resetObjectParams = useCallback(() => {
    dispatch(_resetObjectParams());
  }, [dispatch]);

  return {
    setObjectModalVisibility,
    objectModalVisibility,
    objectParams,
    setObjectParams,
    resetObjectParams,
  };
}

export default useObjectsUi;
