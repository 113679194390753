import { Dialog } from 'primereact/dialog';
import useTemplateUI from '../../hooks/useTemplatesUI';
import { TemplateList } from '../list';

export default function TemplateModal() {
  const { setTemplatesModalVisibility, templateModalVisibility } = useTemplateUI();

  return (
    <Dialog
      onHide={() => setTemplatesModalVisibility(false)}
      headerStyle={{ paddingBlock: 7 }}
      modal
      style={{ width: '80%', height: '90%' }}
      visible={templateModalVisibility}
    >
      <TemplateList />
    </Dialog>
  );
}
