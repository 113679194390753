import SideBar from './components/Sidebar';
import { DocumentViewer } from '../../features/documents';
import { ObjectListModal } from '../../features/objects';
import { useAuth } from '../../features/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardView = () => {
  const navigate = useNavigate();
  const { user, getToken } = useAuth();

  const token = getToken();

  // Redirect to login if user or token is not present. This happens when a user navigates to an external page within the app.
  useEffect(() => {
    if (!token || !user) {
      navigate('/');
    }
  }, [token, user, navigate]);

  return (
    <div style={{ height: '100vh' }} className='flex overflow-hidden no-wrap'>
      <div style={{ flex: '0 0 300px' }} className='h-screen overflow-y-auto'>
        <SideBar />
        <ObjectListModal />
      </div>
      <div style={{ flex: '1 1 auto', background: '#F2F4FA' }}>
        <DocumentViewer />
      </div>
    </div>
  );
};

export default DashboardView;
