import { useCallback, useRef } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useCreateDocumentMutation } from '../documentsApi';
import { CurrentDocumentParams, useDocument } from '../hooks/useDocument';
import useDocumentUi from '../hooks/useDocumentUi';

export interface GenerateDocumentButtonProps {
  isPreview: boolean;
}

type GenerateDocumentProps = ButtonProps & GenerateDocumentButtonProps;

export interface CreateDocumentArgs extends CurrentDocumentParams {
  isPreview: boolean;
}

const GenerateDocumentButton = (props: GenerateDocumentProps) => {
  const { label = 'Generate', isPreview, ...rest } = props;
  const [createDocument, documentMeta] = useCreateDocumentMutation({ fixedCacheKey: 'document' });

  const { documentIsGenerating, documentIsRefreshing } = useDocumentUi();

  const { currentParams } = useDocument();

  const toast = useRef<Toast>(null);

  const generateDocument = useCallback(
    async ({ objectTypeId, objectId, templateId, isPreview }: CreateDocumentArgs) => {
      try {
        const response = await createDocument({ templateId, objectTypeId, objectId, isLive: !isPreview }).unwrap();

        if ('error' in response || !response?.name) throw new Error('Error creating document.');

        if (isPreview) return;

        toast.current?.show({
          severity: 'success',
          summary: 'Document Generated',
          detail: (
            <>
              <b>{response.name}</b> has been saved to the <b>Notes</b> section of this object.
            </>
          ),
          life: 5000,
        });
      } catch (error) {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error was encountered while generating the document.',
          life: 3000,
        });
      }
    },
    [createDocument],
  );

  return (
    <>
      <Button
        disabled={documentMeta?.isLoading || !currentParams || documentIsGenerating || documentIsRefreshing}
        label={label}
        onClick={() => {
          if (!currentParams) return;
          const { objectTypeId, objectId, templateId } = currentParams;
          generateDocument({ objectTypeId, objectId, templateId, isPreview });
        }}
        tooltipOptions={{ showDelay: 1000, position: 'left' }}
        icon='pi pi-file-export'
        iconPos='right'
        loading={isPreview ? documentIsRefreshing : documentIsGenerating}
        {...rest}
      />
      <Toast ref={toast} position='bottom-right' />
    </>
  );
};

export default GenerateDocumentButton;
