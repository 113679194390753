import { useMemo } from 'react';
import { useObjectTypes } from '../../objectTypes';
import { ObjectListQueryResponse, useGetObjectsQuery } from '../objectsApi';
import useObjectsUi from './useObjectsUi';

export interface UseGetObjectsPageReturnType {
  objectsPageMeta: ReturnType<typeof useGetObjectsQuery>;
  objectsPageData: ObjectListQueryResponse | undefined;
}

export function useGetObjectsPage(): UseGetObjectsPageReturnType {
  const { objectTypeId } = useObjectTypes();
  const { objectParams } = useObjectsUi();

  const objectsPageMeta = useGetObjectsQuery(
    { objectType: objectTypeId || '', queryParams: objectParams },
    { skip: !objectTypeId },
  );

  const objectsPageData = useMemo(() => objectsPageMeta.data, [objectsPageMeta]);

  return {
    objectsPageMeta,
    objectsPageData,
  };
}
