import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { authApi, User } from './authApi';

export type AuthState = {
  token: string | null;
  user: User | null;
};

const initialState: AuthState = {
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, payload: PayloadAction<AuthState>) {
      state.user = payload.payload.user;
      state.token = payload.payload.token;
    },
    clearAuth(state) {
      state.user = null;
      state.token = null;
    },
    setToken(state, payload: PayloadAction<string>) {
      state.token = payload.payload;
    },
    setUser(state, payload: PayloadAction<User>) {
      state.user = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = payload;
    });
  },
});

export const { clearAuth, setAuth, setToken, setUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
