import { useCallback, useMemo } from 'react';
import { useLoginMutation } from '../authApi';
import { selectToken, selectUser, setUser as _setUser } from '../authSlice';
import { useSessionStorage } from 'primereact/hooks';

import type { User } from '../authApi';
import { useDispatch, useSelector } from 'react-redux';

export interface UseAuthReturnType {
  user: User | null;
  token: string | null;
  getUser: (token: string) => Promise<User | undefined>;

  setToken: (token: string) => void;
  setUser: (user: User) => void;
  getToken: () => string | null;
}

const SESSION_TOKEN_KEY = 'session_token';

export const retrieveSessionTokenFromSessionStorage = (): string | null => {
  return window.sessionStorage.getItem(SESSION_TOKEN_KEY);
};
export const storeSessionTokenInSession = (token: string) => window.sessionStorage.setItem(SESSION_TOKEN_KEY, token);

export function useAuth(): UseAuthReturnType {
  const [login] = useLoginMutation();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const storeUserInSession = useSessionStorage<User | null>(null, 'sessionToken')[1];

  const dispatch = useDispatch();

  // Set user in session storage and redux store
  const setUser = useCallback(
    (user: User) => {
      dispatch(_setUser(user));
      storeUserInSession(user);
    },
    [dispatch, storeUserInSession],
  );

  // Get user by logging in with the token
  const getUser = useCallback(
    async (token: string) => {
      const userData = await login({ sessionToken: token }).unwrap();

      if (!userData.isAdmin || !userData.objectId || !userData.objectType) return undefined;
      return userData;
    },
    [login],
  );

  return useMemo(
    () => ({
      user,
      token,

      setUser,
      getUser,

      setToken: storeSessionTokenInSession,
      getToken: retrieveSessionTokenFromSessionStorage,
    }),
    [getUser, user, token, setUser],
  );
}

export default useAuth;
