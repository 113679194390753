import { apiSlice } from '../../api';

import { SingleResponse } from '../../api/types';

export type Document = {
  id: string;
  url: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  account: string;
};

export interface CreateDocumentParams {
  objectId: string;
  objectTypeId: string;
  templateId: string;
  isLive?: boolean;
}

export const documentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<Document, CreateDocumentParams>({
      query: ({ objectId, templateId, isLive = false, objectTypeId }) => ({
        url: `/templates/${templateId}/${objectTypeId}/${objectId}`,
        method: 'POST',
        params: isLive ? { active: true } : { preview: true },
      }),
      transformResponse: (response: SingleResponse<Document>) => response.data,
    }),
  }),
});

export const { useCreateDocumentMutation } = documentApi;

export type UseCreateDocumentMutation = typeof useCreateDocumentMutation;
