import { useCallback, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useUpdateTemplateMutation, Template } from '../../templatesApi';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { pick } from 'lodash';
import './.templatesettings.css';
import { InputSwitch } from 'primereact/inputswitch';
import { useTemplates } from '../../hooks';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';

interface Props {
  visible: boolean;
  onHide: () => void;
}

const TemplateSettingsModal = ({ visible, onHide }: Props) => {
  const [updateTemplate, { isLoading }] = useUpdateTemplateMutation();
  const { selectedTemplate } = useTemplates();

  const defaultValues: Template = { id: 'null', name: '', documentNamePattern: '' };

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (selectedTemplate) {
      reset(selectedTemplate);
    }
  }, [selectedTemplate, reset]);

  const onSubmit: SubmitHandler<Template> = useCallback(
    async (data: Template) => {
      if (!selectedTemplate) return;

      const fieldsToUpdate = Object.entries(dirtyFields)
        .filter(([key, value]) => value)
        .map(([key]) => key);
      const update = pick(data, fieldsToUpdate);
      await updateTemplate({ id: selectedTemplate.id, update });
      onHide();
    },
    [updateTemplate, selectedTemplate, dirtyFields, onHide],
  );

  const getFormErrorMessage = (name: keyof Template) => {
    if (!errors[name]) return <small className='p-error'>&nbsp;</small>;

    return <small className='p-error'>{errors[name]?.message}</small>;
  };

  const handleCancel = () => {
    reset();
    onHide();
  };

  const renderHeader = () => (
    <>
      Template settings
      {selectedTemplate?.id && (
        <>
          <Link
            to={`https://app.documint.me/templates/${selectedTemplate.id}`}
            target='_blank'
            className='ml-5 open-template'
            data-pr-tooltip='Open template in Template Designer'
            data-pr-showdelay={500}
          >
            <i className='pi pi-external-link' style={{ fontSize: '14px' }} />
          </Link>
          <Tooltip target='.open-template' />
        </>
      )}
    </>
  );

  // const MARGIN_PATTERN = /^((\.\d+)|(\d+(\.\d+)?))(in|cm|mm|px)$/;

  return (
    <Dialog
      visible={visible}
      onHide={handleCancel}
      header={renderHeader()}
      closable={false}
      style={{}}
      contentClassName='overflow-y-auto pt-3'
    >
      <div id='template-settings' className='card flex flex-col gap-4 justify-content-center w-full min-h-full'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4'>
            {/* Name */}
            <div>
              <label className='text-xs text-neutral-400'>Name</label>
              <h3 className='text-slate-500'>{selectedTemplate?.name}</h3>
            </div>

            {/* IS ACTIVE */}
            <Controller
              name='isActive'
              control={control}
              render={({ field, fieldState }) => (
                <div className='flex flex-col'>
                  <label htmlFor={field.name} className={classNames({ 'p-error': errors.isActive })}></label>
                  <div className='text-xs mb-1 text-slate-400'>Active</div>
                  <InputSwitch
                    inputId={field.name}
                    checked={field.value || false}
                    inputRef={field.ref}
                    className={classNames({ 'p-invalid': fieldState.error })}
                    onChange={(e) => field.onChange(e.value)}
                  />
                  <Link
                    to='https://docs.documint.me/documents/minted-documents'
                    target='_blank'
                    className='cursor mt-2 text-xs text-primary-500'
                  >
                    Read docs
                  </Link>
                </div>
              )}
            />
            {/* <Controller
                  name='name'
                  control={control}
                  rules={{ required: 'Name is required' }}
                  render={({ field, fieldState }) => (
                    <div>
                      <label htmlFor={field.name} className={classNames({ 'p-error': errors.name })}></label>

                      <span className='p-float-label'>
                        <InputText
                          id={field.name}
                          value={field.value}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                        <label htmlFor={field.name}>Name</label>
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                /> */}
            {/* Document Name Pattern */}
            <Controller
              name='documentNamePattern'
              control={control}
              render={({ field, fieldState }) => (
                <div className='flex flex-col'>
                  <label htmlFor={field.name} className='text-xs mb-1 text-slate-400'>
                    Document Name Pattern
                  </label>

                  <InputText
                    id={field.name}
                    value={field.value}
                    className={'p-inputtext-sm' + classNames({ 'p-invalid': fieldState.error })}
                    onChange={(e) => field.onChange(e.target.value)}
                  />

                  <div className='text-xs my-1 leading-5'>
                    <div>
                      Example: &#123;&#123;dealname&#125;&#125; Invoice - &#123;&#123;$date
                      format="MM/DD/YYYY"&#125;&#125;
                    </div>
                    <Link
                      to='https://docs.documint.me/templates/page-settings/document-naming'
                      target='_blank'
                      className='cursor text-primary-500'
                    >
                      Read docs
                    </Link>
                  </div>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>

          {/* MARGIN */}
          {/* <div className='flex-col'>
                <h3 className='text-md mb-2'>Margins</h3>
                <div className='flex flex-col margins items-center gap-6'>
                  <Controller
                    name='options.margin.top'
                    control={control}
                    rules={{ required: 'Name - Surname is required.', pattern: MARGIN_PATTERN }}
                    render={({ field, fieldState }) => (
                      <div>
                        <label
                          htmlFor={field.name}
                          className={classNames({ 'p-error': errors.documentNamePattern })}
                        ></label>
                        <span className='p-float-label'>
                          <InputText
                            id={field.name}
                            value={field.value}
                            className={classNames({ 'p-invalid': fieldState.error })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />

                          <label htmlFor={field.name}>Top</label>
                        </span>
                        
                      </div>
                    )}
                  />

                  <div className='flex items-center gap-5'>
                    <Controller
                      name='options.margin.left'
                      control={control}
                      rules={{ required: 'Name - Surname is required.', pattern: MARGIN_PATTERN }}
                      render={({ field, fieldState }) => (
                        <div className='flex-auto'>
                          <label
                            htmlFor={field.name}
                            className={classNames({ 'p-error': errors.documentNamePattern })}
                          ></label>
                          <span className='p-float-label'>
                            <InputText
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />

                            <label htmlFor={field.name}>Left</label>
                          </span>
                    
                        </div>
                      )}
                    />
                    
                    <div className='flex-auto'>
                      <div className='border p-3 m-2'>
                        <div className='border border-dotted' style={{ width: '130px', height: '180px' }}></div>
                      </div>
                    </div>

                    <Controller
                      name='options.margin.right'
                      control={control}
                      rules={{ required: 'Name - Surname is required.', pattern: MARGIN_PATTERN }}
                      render={({ field, fieldState }) => (
                        <div className='flex-auto'>
                          <label
                            htmlFor={field.name}
                            className={classNames({ 'p-error': errors.documentNamePattern })}
                          ></label>
                          <span className='p-float-label'>
                            <InputText
                              id={field.name}
                              value={field.value}
                              className={classNames({ 'p-invalid': fieldState.error })}
                              onChange={(e) => field.onChange(e.target.value)}
                            />

                            <label htmlFor={field.name}>Right</label>
                          </span>
                          
                        </div>
                      )}
                    />
                  </div>
                  <Controller
                    name='options.margin.bottom'
                    control={control}
                    rules={{ required: 'Name - Surname is required.', pattern: MARGIN_PATTERN }}
                    render={({ field, fieldState }) => (
                      <div className='flex-auto'>
                        <label
                          htmlFor={field.name}
                          className={classNames({ 'p-error': errors.documentNamePattern })}
                        ></label>
                        <span className='p-float-label'>
                          <InputText
                            id={field.name}
                            value={field.value}
                            className={classNames({ 'p-invalid': fieldState.error })}
                            onChange={(e) => field.onChange(e.target.value)}
                          />

                          <label htmlFor={field.name}>Bottom</label>
                        </span>
                      </div>
                    )}
                  />
                </div>
              </div> */}

          <div className='flex gap-3 justify-end mt-10'>
            <Button label='Cancel' type='button' text onClick={handleCancel} />
            <Button label='Save' type='submit' loading={isLoading} />
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default TemplateSettingsModal;
