import { LoadError } from '@react-pdf-viewer/core';
import { Panel } from 'primereact/panel';

export default function LoadErrorMessage({ error }: { error: LoadError }) {
  return (
    <div className='w-full max-h-full overflow-y-auto'>
      <div className='flex justify-center '>
        <div className='w-full max-w-xl bg-white rounded-xl p-6 m-10'>
          <h3 className='text-center text-2xl mb-4'>Unable to load document</h3>
          <div className='flex flex-col gap-2 mb-5 text-md text-neutral-500'>
            <p>An error was encountered while loading the document.</p>
            <p>
              Try creating a new preview. If the error persists please contact{' '}
              <a href='mailto:support@documint.me'>Documint Support</a>
            </p>
          </div>
          <Panel header='Error details' toggleable className='w-full' collapsed={true}>
            {error.message}
          </Panel>
        </div>
      </div>
    </div>
  );
}
