import TemplateListGridItemSkeleton from './TemplateListGridItemSkeleton';

export default function TemplateGridSkeletonLayout() {
  return (
    <div
      className='p-grid grid p-nogutter grid-nogutter grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 p-5'
      data-pc-section='grid'
    >
      {Array.apply(null, Array(20)).map((_, index) => (
        <div key={index}>
          <TemplateListGridItemSkeleton />
        </div>
      ))}
    </div>
  );
}
