import TemplateListItemSkeleton from './TemplateListItemSkeleton';

export default function TemplateListSkeletonLayout() {
  return (
    <div className='flex flex-col gap-10 mt-5'>
      {Array.apply(null, Array(20)).map((_, index) => (
        <div key={index}>
          <TemplateListItemSkeleton />
        </div>
      ))}
    </div>
  );
}
