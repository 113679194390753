import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { ListQueryParams, ListResponse } from '../../api/types';
import { ExpandedObjectInterface, objectsApi } from './objectsApi';
import { setObjectTypeId } from '../objectTypes';
import { authApi } from '../auth/authApi';

const objectListAdapter = createEntityAdapter<ExpandedObjectInterface>({});

const DEFAULT_OBJECT_LIMIT = 25;

export type ObjectsSliceState = {
  data: {
    objectId: string | null;
    objectList: ReturnType<typeof objectListAdapter.getInitialState>;
  };
  ui: {
    modalVisible: boolean;
    params: ListQueryParams;
  };
};

const initialState: ObjectsSliceState = {
  data: {
    objectId: null,
    objectList: objectListAdapter.getInitialState(),
  },
  ui: {
    modalVisible: false,
    params: {
      limit: DEFAULT_OBJECT_LIMIT,
      sortOrder: 'ASCENDING',
      sortBy: undefined,
      after: 0,
      filterOperator: undefined,
      searchProperty: undefined,
      searchValue: undefined,
    },
  },
};

export const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    // Object data actions
    setObjectId(state, action: PayloadAction<string>) {
      if (typeof action.payload !== 'string') {
        console.error('selectObjectId action.payload is not a string:', action.payload);
        state.data.objectId = null;
        return;
      }
      state.data.objectId = action.payload;
    },
    clearObjectList(state, _) {
      objectListAdapter.removeAll(state.data.objectList);
    },
    resetObjectsData(state) {
      state.data.objectId = null;
      objectListAdapter.removeAll(state.data.objectList);
    },

    // Object UI actions
    setObjectModalVisibility(state, action: PayloadAction<boolean>) {
      if (action.payload === false) {
        state.ui.params = initialState.ui.params;
      }
      state.ui.modalVisible = action.payload;
    },
    setObjectParams(state, action: PayloadAction<Partial<ListQueryParams>>) {
      state.ui.params = {
        ...state.ui.params,
        ...action.payload,
        searchValue: action.payload.searchValue || undefined,
      };
    },
    resetObjectParams(state) {
      state.ui.params = initialState.ui.params;
    },
  },
  extraReducers: (builder) => {
    // Cleared object dat when object type changes
    builder.addCase(setObjectTypeId, (state, _) => {
      state.data.objectId = null;
      objectListAdapter.removeAll(state.data.objectList);
      state.ui.params.after = 0;
    });

    // sets the objectId from the user login response
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.data.objectId = payload.objectId || null;
    });

    // Builds the object entity adapter from the API responses
    builder.addMatcher(
      objectsApi.endpoints.getObject.matchFulfilled,
      (state: ObjectsSliceState, action: PayloadAction<ExpandedObjectInterface>) => {
        objectListAdapter.addOne(state.data.objectList, action.payload);
      },
    );
    builder.addMatcher(
      objectsApi.endpoints.getDefaultObject.matchFulfilled,
      (state: ObjectsSliceState, action: PayloadAction<ExpandedObjectInterface>) => {
        objectListAdapter.addOne(state.data.objectList, action.payload);
      },
    );
    builder.addMatcher(
      objectsApi.endpoints.getObjects.matchFulfilled,
      (state: ObjectsSliceState, action: PayloadAction<ListResponse<ExpandedObjectInterface>>) => {
        objectListAdapter.addMany(state.data.objectList, action.payload.data);
      },
    );
  },
});

export const {
  setObjectId,
  clearObjectList,
  resetObjectsData,

  setObjectModalVisibility,
  resetObjectParams,
  setObjectParams,
} = objectsSlice.actions;

export const selectObjectId = (state: RootState) => state.objects.data.objectId;
export const selectObjectList = (state: RootState) => state.objects.data.objectList;
export const selectObjectModalVisibility = (state: RootState) => state.objects.ui.modalVisible;
export const selectObjectParams = (state: RootState) => state.objects.ui.params;

export const {
  selectAll: selectAllObjects,
  selectById: selectByObjectId,
  selectIds: selectObjectsByIds,
  selectTotal: selectTotalNumberOfObjects,
} = objectListAdapter.getSelectors((state: RootState) => state.objects.data.objectList);

export default objectsSlice.reducer;
