import { Template } from '../templatesApi';
import { Message } from 'primereact/message';
import { Link } from 'react-router-dom';

type TemplateActiveStateNoteProps = {
  template?: Template;
};

const TemplateActiveStateNote = (props: TemplateActiveStateNoteProps) => {
  return (
    <Message
      text={
        <span className='text-sm'>
          Documents created from{' '}
          <Link to='https://docs.documint.me/documents/minted-documents' className='underline' target='_blank'>
            inactive
          </Link>{' '}
          templates are{' '}
          <Link to='https://docs.documint.me/documents/minted-documents' className='underline' target='_blank'>
            watermarked
          </Link>
          .
        </span>
      }
      className='w-full text-sm'
      {...props}
    />
  );
};

export default TemplateActiveStateNote;
