import { useCallback } from 'react';
import {
  selectTemplateById,
  selectAllTemplates,
  selectTemplateId,
  setTemplateId as _setTemplateId,
} from '../templatesSlice';
import { type Template } from '../templatesApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/index';

export interface UseTemplatesReturnType {
  selectedTemplate: Template | undefined;
  templateId: string | null;
  allTemplates: Template[];
  setTemplateId: (templateId: string) => void;
}

export function useTemplates() {
  const allTemplates = useSelector(selectAllTemplates);
  const templateId = useSelector(selectTemplateId);

  const dispatch = useDispatch();

  const setTemplateId = useCallback(
    (templateId: string) => {
      dispatch(_setTemplateId(templateId));
    },
    [dispatch],
  );

  const selectedTemplate = useSelector((state: RootState) => selectTemplateById(state, templateId || ''));

  return {
    selectedTemplate,
    templateId,
    allTemplates,
    setTemplateId,
  };
}
