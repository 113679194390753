import { Column } from 'primereact/column';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import PropertyTableColumnBody from './PropertyTableColumnBody';
import renderTypeColumnBody from './renderTypeColumnBody';
import renderDefinedByColumnBody from './renderDefinedByColumnBody';

import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Icon from '../../../../components/InfoIcon';
import { FilterMatchMode } from 'primereact/api';
import usePropertiesUi from '../../hooks/usePropertiesUi';
import { useGetProperties } from '../../hooks';
import { CustomProperty } from '../../propertiesApi';
import { filterActivePropertyGroup } from '../../utils';

export function PropertiesTable() {
  const [activeProperties, setActiveProperties] = useState<CustomProperty[]>([]);

  const { searchValue, definedBy, groupName, activePropertyGroup, useType } = usePropertiesUi();
  const { properties, propertiesMeta } = useGetProperties();

  // Get subset of properties based on the active property group
  useEffect(() => {
    const filteredProperties = filterActivePropertyGroup(properties, activePropertyGroup);
    setActiveProperties(filteredProperties);
  }, [properties, activePropertyGroup]);

  // Filters for the DataTable
  const filters: DataTableFilterMeta = useMemo(() => {
    return {
      global: { value: searchValue, matchMode: FilterMatchMode.CONTAINS },
      isCustom: {
        value: definedBy === 'all' ? [true, false] : definedBy === 'hubspot' ? [false] : [true],
        matchMode: FilterMatchMode.IN,
      },
      groupName: {
        value: activePropertyGroup === 'primary' ? groupName : null,
        matchMode: FilterMatchMode.EQUALS,
      },
    };
  }, [searchValue, definedBy, groupName, activePropertyGroup]);

  // Renders the header for the property column. Used to display the association name when looping over associated properties
  const renderPropertyHeader = useCallback(() => {
    if (activePropertyGroup === 'primary' || useType === 'first') {
      return <p>Properties</p>;
    } else {
      const tooltipForAssociationName = `Use '${activePropertyGroup}' as the list variable when looping/repeating an element in your template.
                      See Instructions for more details.`;
      return (
        <div className='flex items-center gap-2'>
          <div className=' bg-sky-50 p-1 px-2 border-2 border-sky-150 rounded-md'>
            <p>{activePropertyGroup}</p>
          </div>
          <Icon id={activePropertyGroup} key={activePropertyGroup} tooltip={tooltipForAssociationName} />
        </div>
      );
    }
  }, [activePropertyGroup, useType]);

  if (propertiesMeta.isLoading) {
    return (
      <div className='flex h-full w-full justify-center items-center' style={{ height: 'calc(100vh - 220px)' }}>
        <LoadingSpinner text='Loading properties' />
      </div>
    );
  }

  return (
    <DataTable
      value={activeProperties}
      // virtual scrolling
      scrollable
      virtualScrollerOptions={{
        itemSize: 82,
      }}
      scrollHeight='calc(100vh - 140px)'
      // filters
      filters={filters}
      globalFilterFields={['label', 'name', 'groupName']}
      filterDisplay='menu'
      // misc
      loading={propertiesMeta.isFetching}
      stripedRows
      style={{ height: '100%', overflow: 'hidden' }}
      pt={{
        bodyRow: { style: { height: '82px' } },
        loadingOverlay: { style: { height: '100%', backgroundColor: 'rgba(255,255,255,.7)' } },
      }}
    >
      <Column field='label' header='Name' className='overflow-x-hidden text-ellipsis' sortable />
      <Column
        field='name'
        header={renderPropertyHeader}
        body={(property) => <PropertyTableColumnBody property={property} />}
      />
      {activePropertyGroup === 'primary' && <Column field='groupName' header='Group' sortable />}
      <Column field='type' header='Type' body={renderTypeColumnBody} />
      <Column field='isCustom' header='' style={{ width: '70px' }} body={renderDefinedByColumnBody} />
    </DataTable>
  );
}

export default PropertiesTable;
