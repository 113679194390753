import { forwardRef, Ref, useCallback, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import type { ColumnProps } from './ObjectTable';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

type ColumnSelectMenuProps = {
  columns: ColumnProps[];
  visibleColumns: ColumnProps[];
  onSave: (values: any[]) => void;
  onCancel: () => void;
  ref: Ref<OverlayPanel>;
};

const ColumnSelectMenu = forwardRef<OverlayPanel, ColumnSelectMenuProps>((props, ref) => {
  const { visibleColumns, columns, onSave, onCancel } = props;
  const [selectedColumns, setSelectedColumns] = useState<ColumnProps[]>(visibleColumns || []);

  const handleOptionChange = useCallback((col: ColumnProps) => {
    setSelectedColumns((prev) => {
      if (prev.find((o) => o.field === col.field)) {
        return prev.filter((o) => o.field !== col.field);
      } else {
        return [...prev, col];
      }
    });
  }, []);

  return (
    <OverlayPanel
      ref={ref}
      pt={{ root: { style: { position: 'absolute', zIndex: 1000 } } }}
      className='-translate-x-28'
    >
      <div className='flex flex-col w-[10rem]'>
        <h5 className='text-lg font-medium text-center mb-2'>Select Columns</h5>
        {columns.map((col: ColumnProps) => {
          const isSelected = Boolean(selectedColumns.find((o) => o.field === col.field));
          return (
            <div
              key={col.field}
              className={`flex gap-3 align-items-center px-3 py-2 rounded-sm cursor-pointer ${
                isSelected ? 'bg-sky-50 hover:bg-sky-100' : 'bg-white hover:bg-sky-50'
              }
              `}
              onClick={() => handleOptionChange(col)}
            >
              <Checkbox inputId={col.field} name={col.label} value={col} checked={isSelected} />
              <p>{col.label}</p>
            </div>
          );
        })}
      </div>
      <div className='flex gap-3 justify-around mt-4'>
        <Button
          icon='pi pi-times'
          size='small'
          outlined
          severity='danger'
          aria-label='Cancel'
          onClick={() => {
            setSelectedColumns(visibleColumns);
            onCancel();
          }}
        />
        <Button icon='pi pi-check' size='small' outlined aria-label='Filter' onClick={() => onSave(selectedColumns)} />
      </div>
    </OverlayPanel>
  );
});

export default ColumnSelectMenu;
