import { useEffect, useMemo, useRef, useState } from 'react';
import { Template } from '../../templatesApi';
import { useTemplates } from '../../hooks';
import { Dropdown, DropdownFilterEvent, DropdownPassThroughOptions, DropdownProps } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import TemplateSettingsModal from '../settings/TemplateSettingsModal';
import useTemplateUI from '../../hooks/useTemplatesUI';
import TemplateModal from './TemplateModal';
import { HiCog } from 'react-icons/hi';
import { useGetTemplatesPage } from '../../hooks/useGetTemplatesPage';
import { useDebounce } from 'primereact/hooks';

interface TemplateOption {
  label: string;
  value: Template;
}
type TemplateDropdownProps = Omit<DropdownProps, 'options' | 'value'> & {
  label?: any;
};

export default function TemplateDropdown(props: TemplateDropdownProps) {
  const { label = {}, ...rest } = props;
  const { className: labelClassName = '', ...labelProps } = label;

  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const [showTemplateSettings, setShowTemplateSettings] = useState(false);

  const templateDropdownRef = useRef<Dropdown>(null);

  // template hooks
  const { setTemplatesModalVisibility, setTemplatesSearchValue } = useTemplateUI();
  const { selectedTemplate, setTemplateId } = useTemplates();
  const { templatesPageData, templatesPageMeta } = useGetTemplatesPage();

  const [instantSearchValue, debouncedSearchValue, setDebouncedSearchValue] = useDebounce<string | undefined>(
    undefined,
    500,
  );

  // Get the first 10 templates
  const templates = useMemo(() => templatesPageData?.data?.slice(0, 10) || [], [templatesPageData?.data]);

  // Use effect assign template options and ensure selected template is always in the list
  useEffect(() => {
    if (!selectedTemplate) return;

    // Removes the selected template from the list of templates, so we do not have duplicates
    const filteredTemplates = templates.filter((template) => template.id !== selectedTemplate?.id);
    setTemplateOptions([
      { label: selectedTemplate.name || '', value: selectedTemplate },
      ...filteredTemplates.map((template) => ({ label: template.name || '', value: template })),
    ]);
  }, [selectedTemplate, templates]);

  // See dropdown to see where these templates are used.
  const valueTemplate = (option: TemplateOption) => {
    const { value: template } = option || {};
    return (
      <p
        style={{ fontSize: '1rem' }}
        className={`before:content-['●'] before:mr-2 ${
          template?.isActive ? ' before:text-primary-500 ' : 'before:text-slate-200'
        }`}
      >
        {template?.name || 'Select a Template'}
      </p>
    );
  };

  const itemTemplate = (option: TemplateOption) => {
    const { value: template } = option || {};
    return (
      <p
        className={`before:content-['●'] before:mr-2 before:text-xl ${
          template.isActive ? ' before:text-primary-500' : 'before:text-slate-200'
        }`}
      >
        {template?.name}
      </p>
    );
  };

  const dropdownFooterTemplate = () => {
    return (
      <Button
        text
        iconPos='right'
        onClick={() => {
          templateDropdownRef.current?.hide();
          setTemplatesModalVisibility(true);
        }}
        icon='pi pi-search'
        label='Advanced Search'
        size='small'
      />
    );
  };

  useEffect(() => {
    setTemplatesSearchValue(debouncedSearchValue || '');
  }, [debouncedSearchValue, setTemplatesSearchValue]);

  const ptOptions: DropdownPassThroughOptions = useMemo(
    () => ({
      panel: { style: { maxWidth: '10rem' } },
      footer: { style: { padding: 5, paddingBottom: 10, display: 'flex', justifyContent: 'center' } },
      filterInput: { value: instantSearchValue || '' },
    }),
    [instantSearchValue],
  );

  return (
    <>
      {/* dropdown label and settings button */}
      <label htmlFor='template-dropdown' className={`flex items-center gap-2 ${labelClassName}`} {...labelProps}>
        <p>Template</p>

        <HiCog className='cursor-pointer text-slate-500 h-full text-md' onClick={() => setShowTemplateSettings(true)} />
      </label>

      {/* dropdown menu */}
      <Dropdown
        ref={templateDropdownRef}
        id='template-dropdown'
        options={templateOptions}
        value={selectedTemplate}
        filter
        resetFilterOnHide
        onChange={(e) => setTemplateId(e.value.id)}
        onFilter={(e: DropdownFilterEvent) => setDebouncedSearchValue(e.filter)}
        placeholder='Select a Template'
        itemTemplate={itemTemplate}
        loading={templatesPageMeta?.isFetching}
        panelFooterTemplate={dropdownFooterTemplate}
        valueTemplate={valueTemplate}
        pt={ptOptions}
        {...rest}
      />

      <TemplateModal />
      {/* modal for template settings */}
      <TemplateSettingsModal visible={showTemplateSettings} onHide={() => setShowTemplateSettings(false)} />
    </>
  );
}
