import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Link, useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import config from '../../../config';
import { useAuth } from '../../../features/auth';
import { TemplateActiveStateNote, TemplateDropdown, useTemplates } from '../../../features/templates';
import { GenerateDocumentButton } from '../../../features/documents';
import { ObjectTypeDropdown } from '../../../features/objectTypes';
import { ObjectDropdown } from '../../../features/objects';
import { useObjectTypes } from '../../../features/objectTypes';
import useDocumentUi from '../../../features/documents/hooks/useDocumentUi';
import { useGetPropertiesQuery } from '../../../features/properties';

export default function SideBar() {
  const { user } = useAuth();

  const { setCreatorMode, creatorMode } = useDocumentUi();

  const { objectTypeId } = useObjectTypes();
  const { selectedTemplate: template } = useTemplates();

  const initialized = useRef<boolean>(false);

  const navigate = useNavigate();

  // Prefetches properties for the selected object type
  useGetPropertiesQuery(objectTypeId || '', { skip: !objectTypeId || !creatorMode });

  /**
   * Initialize creator mode if user is admin
   */
  useEffect(() => {
    if (!initialized.current) {
      if (user?.isAdmin && !creatorMode) setCreatorMode?.(true);
      initialized.current = true;
    }
  }, [user, setCreatorMode, creatorMode]);

  /**
   * Main Component
   */
  return (
    <div className='flex flex-col gap-5 pr-4 pl-1 h-full justify-between'>
      <div className='flex flex-col gap-4'>
        {/* label includes a dropdown for the object type controls */}
        <label>
          <ObjectTypeDropdown
            style={{ border: 'none' }}
            filter={false}
            filterPlaceholder='Filter...'
            className='mt-2 !shadow-none !border-none'
            pt={{
              input: {
                style: {
                  fontSize: '16px',
                  padding: '0',
                },
                className: 'text-slate-600',
              }, // Maybe remove focus outline
              filterInput: { style: { padding: '5px 10px' } },
            }}
          />
        </label>

        {/* Object Controls */}
        <ObjectDropdown />

        {/* line separates object controls and template controls */}
        <hr className='border-neutral-200 border-1 my-3' />

        {/* Template Controls */}
        <TemplateDropdown label={{ className: 'text-slate-600' }} />

        <GenerateDocumentButton
          tooltip='Generate document and save as note'
          className='flex-auto absolute top-4 right-8 z-[1000]'
          isPreview={false}
        />

        <div className='flex flex-col gap-4'>
          {template && (
            <div className='flex flex-col gap-4'>
              {/* Notice about inactive template */}
              {template?.isActive === false && <TemplateActiveStateNote />}

              <GenerateDocumentButton
                className='flex-auto'
                label='Refresh Preview'
                icon='pi pi-refresh'
                iconPos='left'
                isPreview={true}
                outlined
              />
            </div>
          )}
          {/* Properties BUTTON */}
          {creatorMode && (
            <Button
              onClick={() => navigate('/properties')}
              outlined
              label={`View Properties`}
              icon={<>&#123;...&#125;</>}
            />
          )}
        </div>
      </div>

      {/* Footer */}
      <div className='flex justify-around items-center'>
        <div className='flex flex-auto gap-3 items-center justify-start'>
          <label htmlFor='editor-mode' className='text-sm text-neutral-500'>
            Editor Mode
          </label>
          <InputSwitch
            inputId='editor-mode'
            checked={creatorMode}
            onChange={(e) => setCreatorMode?.(e.target.value)}
            style={{ scale: '.8' }}
          />
        </div>

        {/* Debug Info Download */}
        {/* Debug is currently broken and is not being used anyways */}
        {/* {creatorMode && (
          <div className='flex-auto'>
            <DebugButton template={template} />
          </div>
        )} */}

        <Link to={config.docsURL} className='text-primary-500' target='_blank'>
          <i className='pi pi-book mr-2' />
          Help Center
        </Link>
      </div>
    </div>
  );
}
