import { Dialog } from 'primereact/dialog';
import ObjectList from './ObjectList';
import { useObjectsUi } from '../../hooks';

export default function ObjectListModal() {
  const { setObjectModalVisibility, objectModalVisibility } = useObjectsUi();
  return (
    <Dialog
      onHide={() => setObjectModalVisibility(false)}
      headerStyle={{ paddingBlock: 7 }}
      modal
      style={{ width: '80%', height: '90%' }}
      visible={objectModalVisibility}
      draggable={false}
    >
      <ObjectList />
    </Dialog>
  );
}
