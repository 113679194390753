import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { apiSlice } from '../api';

// Reducers
import authReducer from '../features/auth/authSlice';
import objectTypesReducer from '../features/objectTypes/objectTypesSlice';
import propertiesReducer from '../features/properties/propertiesSlice';
import templatesReducer from '../features/templates/templatesSlice';
import objectsReducer from '../features/objects/objectsSlice';
import documentReducer from '../features/documents/documentSlice';

export const store = configureStore({
  devTools: {
    name: 'Documint',
  },
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    templates: templatesReducer,
    objectTypes: objectTypesReducer,
    objects: objectsReducer,
    document: documentReducer,
    properties: propertiesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
