import { CustomProperty } from '../../properties/propertiesApi';

export function snakeCaseToTitleCase(str: string) {
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function CapitalizeFirstCharacter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function filterPrimaryProperties(properties: CustomProperty[]): CustomProperty[] {
  return properties.filter(
    (prop) => !prop.relatedObjectProperties && !prop.relatedObjectType && prop.groupId !== 'associations',
  );
}

export function filterAssociatedProperties(properties: CustomProperty[]): CustomProperty[] {
  return properties.filter(
    (prop) => prop.relatedObjectProperties && prop.relatedObjectType && prop.groupId === 'associations',
  );
}

// Divides properties into primary and associated properties
export function splitPrimaryAndAssociatedProperties(properties: CustomProperty[]): {
  primary: CustomProperty[];
  associated: CustomProperty[];
} {
  const associated = filterAssociatedProperties(properties);
  const primary = filterPrimaryProperties(properties);
  return { primary, associated };
}

// Filters properties based on the active property group
export function filterActivePropertyGroup(properties: CustomProperty[], activePropertyGroup: string): CustomProperty[] {
  if (activePropertyGroup === 'primary') {
    return filterPrimaryProperties(properties);
  } else {
    const property = properties.find((prop) => prop.name === activePropertyGroup);
    return property?.relatedObjectProperties || [];
  }
}
