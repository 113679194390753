import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActiveGroupType,
  setPropertiesUi as _setPropertiesUi,
  resetPropertiesUi as _resetPropertiesUi,
  PropertiesUiSliceState,
  selectActivePropertyGroup,
  selectGroupName,
  selectDefinedBy,
  selectIsFormatted,
  selectSearchValue,
  selectUseType,
} from '../propertiesSlice';

import type { DefinedBy, UseType } from '../propertiesSlice';

interface UsePropertiesUiReturnType {
  definedBy: DefinedBy;
  isFormatted: boolean;
  useType: UseType;
  activePropertyGroup: ActiveGroupType;
  groupName: string | undefined;
  onPrimaryPropertyGroup: boolean;
  searchValue: string;

  setPropertiesUi: (propertiesUi: Partial<PropertiesUiSliceState>) => void;
  resetPropertiesUi: () => void;
}

export default function usePropertiesUi(): UsePropertiesUiReturnType {
  const definedBy = useSelector(selectDefinedBy);
  const isFormatted = useSelector(selectIsFormatted);
  const useType = useSelector(selectUseType);
  const activePropertyGroup = useSelector(selectActivePropertyGroup);
  const groupName = useSelector(selectGroupName);
  const searchValue = useSelector(selectSearchValue);

  const dispatch = useDispatch();

  const onPrimaryPropertyGroup = useMemo(() => activePropertyGroup === 'primary', [activePropertyGroup]);

  const setPropertiesUi = useCallback(
    (args: Partial<PropertiesUiSliceState>) => {
      dispatch(_setPropertiesUi(args));
    },
    [dispatch],
  );

  const resetPropertiesUi = useCallback(() => {
    dispatch(_resetPropertiesUi());
  }, [dispatch]);

  return useMemo(
    () => ({
      definedBy,
      isFormatted,
      useType,
      onPrimaryPropertyGroup,
      setPropertiesUi,
      resetPropertiesUi,
      activePropertyGroup,
      groupName,
      searchValue,
    }),
    [
      definedBy,
      isFormatted,
      useType,
      onPrimaryPropertyGroup,
      setPropertiesUi,
      resetPropertiesUi,
      groupName,
      activePropertyGroup,
      searchValue,
    ],
  );
}
