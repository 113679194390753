/**
 * This component displays the object the user is currently working on and allows
 * the user to change objects. See objeccts view.
 */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'primereact/hooks';
import { mapObjectToObjectListItem, startsWithVowel } from '../../utils';
import { useGetObjectsPage, useObjectsUi } from '../../hooks';

// Components
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import type { DropdownChangeEvent, DropdownFilterEvent } from 'primereact/dropdown';
import type { ExpandedObjectInterface } from '../../objectsApi';
import type { SelectItemOptionsType } from 'primereact/selectitem';
import useObjects from '../../hooks/useObjects';
import { useObjectTypes } from '../../../objectTypes';

export function ObjectDropdown() {
  const [options, setOptions] = useState<SelectItemOptionsType | []>([]);

  const dropdownRef = useRef<Dropdown>(null);

  const { setObjectId, selectedObject, objectId } = useObjects();
  const { objectsPageData, objectsPageMeta } = useGetObjectsPage();
  const { setObjectModalVisibility, setObjectParams } = useObjectsUi();
  const { selectedObjectType } = useObjectTypes();

  const [instantSearchValue, debouncedSearchValue, setDebouncedSearchValue] = useDebounce<string | undefined>(
    undefined,
    500,
  );

  const { singularLabel = 'object', pluralLabel = 'objects' } = selectedObjectType || {};
  const isError = objectsPageMeta.isError;

  // We need to set the options for the dropdown in a useEffect because selectedObject and objects need to be
  // coordinated to ensure race conditions don't mess with the dropdown options.
  useEffect(() => {
    const objectData = objectsPageData?.data?.slice(0, 24) || [];

    if (!selectedObject) {
      setOptions(objectData.slice(0, 24).map((object: ExpandedObjectInterface) => mapObjectToObjectListItem(object)));
      return;
    }

    let objectOptions: ExpandedObjectInterface[] = [];
    if (objectData.find((object: ExpandedObjectInterface) => object.id === selectedObject.id)) {
      objectOptions = objectData.filter((object: ExpandedObjectInterface) => object.id !== selectedObject.id);
    } else {
      objectOptions = objectData.slice(0, 24);
    }
    objectOptions.unshift(selectedObject);
    setOptions(objectOptions.map((object) => mapObjectToObjectListItem(object)));
  }, [objectsPageData, selectedObject]);

  // Convert selected object to a string for the dropdown
  const value: string | undefined = useMemo(() => {
    return selectedObject ? mapObjectToObjectListItem(selectedObject).value : undefined;
  }, [selectedObject]);

  // Tracks if the data is being fetched in any manner
  const gettingData = useMemo(() => {
    // The last condition indicates that the objectId is set but the selectedObject is not
    const { isFetching, isLoading, isUninitialized } = objectsPageMeta;
    return isFetching || isLoading || isUninitialized || (objectId && !selectedObject);
  }, [objectsPageMeta, objectId, selectedObject]);

  useEffect(() => {
    setObjectParams({ searchValue: debouncedSearchValue });
  }, [debouncedSearchValue, setObjectParams]);

  /* Dropdown for the object controls */
  return (
    <>
      <Dropdown
        ref={dropdownRef}
        value={value}
        className='mb-0'
        filter
        onFilter={(e: DropdownFilterEvent) => setDebouncedSearchValue(e.filter)}
        emptyMessage={
          objectsPageMeta.isError
            ? // If there are no options because of an error, display an error message
              `Error fetching ${selectedObjectType?.pluralLabel?.toLowerCase()}. Try refreshing the page.`
            : gettingData
            ? // Loading options needs to work in conjunction with clearing options after each objectType change
              'Loading options...'
            : 'No available options'
        }
        options={options}
        // loading={gettingData}
        placeholder={gettingData ? `Loading ${pluralLabel}` : `Select a ${singularLabel}`}
        onChange={(e: DropdownChangeEvent) => setObjectId(e.value?.id)}
        required
        resetFilterOnHide
        panelFooterTemplate={() => (
          <Button
            text
            iconPos='right'
            onClick={() => {
              dropdownRef.current?.hide();
              setObjectModalVisibility(true);
            }}
            icon='pi pi-search'
            label='Advanced Search'
            size='small'
          />
        )}
        pt={{
          emptyMessage: { style: { color: isError ? 'red' : 'black' } },
          filterInput: { value: instantSearchValue || '' },
          footer: { className: 'flex justify-center px-3 py-3 border-t border-gray-200' },
          panel: { style: { maxWidth: '10rem' } },
        }}
      />
      {!selectedObject && !gettingData && (
        // Mimic the appearance of prime react button layout (plus some padding on the right)
        <Message
          pt={{
            root: {
              className: 'flex !items-center pl-[20px] pr-[30px] text-center',
            },
            icon: {
              className: 'basis-initial',
            },
            text: {
              className: 'basis-full',
            },
          }}
          severity={isError ? 'error' : 'info'}
          text={
            objectsPageMeta.isError
              ? `Error fetching ${selectedObjectType?.pluralLabel?.toLowerCase() || 'objects'}`
              : `Select ${!selectedObjectType || startsWithVowel(selectedObjectType?.objectTypeName) ? 'an' : 'a'} ${
                  selectedObjectType?.singularLabel?.toLowerCase() || 'object'
                } to continue`
          }
        />
      )}
    </>
  );
}

export default ObjectDropdown;
