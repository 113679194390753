import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import usePropertiesUi from '../../hooks/usePropertiesUi';

export default function PropertiesSearchInput() {
  const { searchValue, setPropertiesUi } = usePropertiesUi();

  return (
    <IconField iconPosition='left' className='w-full'>
      {searchValue !== '' ? (
        <InputIcon className='pi pi-times' onClick={() => setPropertiesUi({ searchValue: '' })}></InputIcon>
      ) : (
        <InputIcon className='pi pi-search'></InputIcon>
      )}
      <InputText
        className='p-inputtext-md w-full'
        placeholder='Search Properties'
        type='text'
        value={searchValue}
        onChange={(e) => setPropertiesUi({ searchValue: e.target.value })}
      />
    </IconField>
  );
}
