import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActiveOption,
  LayoutOption,
  selectTemplateParams,
  selectTemplatesActiveOption,
  selectTemplatesLayout,
  selectTemplatesModalVisibility,
  resetTemplateParams as _resetTemplateParams,
  setTemplateParams as _setTemplateParams,
  setTemplatesActiveOption as _setTemplatesActiveOption,
  setTemplatesModalVisibility as _setTemplatesModalVisibility,
  setTemplatesLayout as _setTemplatesLayout,
  setTemplatesSearchValue as _setTemplatesSearchValue,
  selectTemplatesSearchValue,
  clearTemplatesSearchValue as _clearTemplatesSearchValue,
} from '../templatesSlice';
import type { TemplatesQuery } from '../templatesApi';

export interface UseTemplateUIReturnType {
  templateModalVisibility: boolean;
  setTemplatesModalVisibility: (visible: boolean) => void;

  templateParams: TemplatesQuery;
  setTemplateParams: (params: TemplatesQuery) => void;
  resetTemplateParams: () => void;

  templatesLayout: LayoutOption;
  setTemplatesLayout: (layout: LayoutOption) => void;

  templatesActiveOption: ActiveOption;
  setTemplatesActiveOption: (option: ActiveOption) => void;

  templatesSearchValue: string | undefined;
  setTemplatesSearchValue: (searchValue: string | undefined) => void;
  clearTemplatesSearchValue: () => void;
}

export default function useTemplateUI(): UseTemplateUIReturnType {
  const templateModalVisibility = useSelector(selectTemplatesModalVisibility);
  const templateParams = useSelector(selectTemplateParams);
  const templatesLayout = useSelector(selectTemplatesLayout);
  const templatesActiveOption = useSelector(selectTemplatesActiveOption);
  const templatesSearchValue = useSelector(selectTemplatesSearchValue);

  const dispatch = useDispatch();

  const setTemplatesModalVisibility = useCallback(
    (visible: boolean) => {
      dispatch(_setTemplatesModalVisibility(visible));
    },
    [dispatch],
  );

  const setTemplateParams = useCallback(
    (params: TemplatesQuery) => {
      dispatch(_setTemplateParams(params));
    },
    [dispatch],
  );

  const resetTemplateParams = useCallback(() => {
    dispatch(_resetTemplateParams());
  }, [dispatch]);

  const setTemplatesLayout = useCallback(
    (layout: LayoutOption) => {
      dispatch(_setTemplatesLayout(layout));
    },
    [dispatch],
  );

  const setTemplatesActiveOption = useCallback(
    (option: ActiveOption) => {
      dispatch(_setTemplatesActiveOption(option));
    },
    [dispatch],
  );

  const setTemplatesSearchValue = useCallback(
    (searchValue: string | undefined) => {
      dispatch(_setTemplatesSearchValue(searchValue || undefined));
    },
    [dispatch],
  );

  const clearTemplatesSearchValue = useCallback(() => {
    dispatch(_clearTemplatesSearchValue());
  }, [dispatch]);

  return {
    templateModalVisibility,
    setTemplatesModalVisibility,
    templateParams,
    setTemplateParams,
    resetTemplateParams,
    templatesLayout,
    setTemplatesLayout,
    templatesActiveOption,
    setTemplatesActiveOption,
    templatesSearchValue,
    setTemplatesSearchValue,
    clearTemplatesSearchValue,
  };
}
