import { useMemo } from 'react';
import {
  useAddApiKeyMutation,
  useLazyCheckApiKeyValidityQuery,
  useLazyGetApiKeyQuery,
  useValidateApiKeyMutation,
} from '../authApi';

export const useApiKey = () => {
  const [checkValidity, checkValidityMeta] = useLazyCheckApiKeyValidityQuery();
  const [save, saveMeta] = useAddApiKeyMutation();
  const [get, getMeta] = useLazyGetApiKeyQuery();
  const [validate, validateMeta] = useValidateApiKeyMutation();

  return useMemo(
    () => ({
      checkValidity,
      checkValidityMeta,
      save,
      saveMeta,
      get,
      getMeta,
      validate,
      validateMeta,
    }),
    [checkValidity, checkValidityMeta, save, saveMeta, get, getMeta, validate, validateMeta],
  );
};

export default useApiKey;
