import { Link, useNavigate } from 'react-router-dom';
import { PropertiesMenu, PropertiesTable, ViewControls } from '../../features/properties';
import config from '../../config';
import { Button } from 'primereact/button';
import RefetchPropertiesButton from '../../features/properties/components/controls/RefetchPropertiesButton';

export default function PropertiesPage() {
  const navigate = useNavigate();
  return (
    <div className='w-full h-screen flex flex-col gap-2'>
      {/* header */}
      <div className='flex items-center justify-between flex-initial w-full'>
        {/* back button */}
        <Button
          size='small'
          tooltip='Back'
          tooltipOptions={{ showDelay: 1000 }}
          icon='pi pi-arrow-left'
          onClick={() => navigate('/dashboard')}
          outlined
          label='Back'
        />

        {/* title */}
        <h4 className='text-2xl font-medium text-slate-600'>Properties</h4>

        {/* refetch button */}
        <RefetchPropertiesButton />
      </div>

      {/* body */}
      <div className='flex w-full justify-between flex-1 h-full'>
        {/* dashboard. contains search, properties menu, and help */}
        <div className='w-[15%] flex flex-col justify-between items-start h-full gap-2 min-w-[225px] max-w-[250px]'>
          {/* search input and menu */}
          <div className='flex flex-col w-full gap-2 h-[90%] bg-white'>
            {/* menu */}
            <PropertiesMenu />
          </div>

          {/* help link */}
          <div className='w-full flex basis-0 justify-center mb-2'>
            <Link to={config.docsURL} target='_blank' className='p-button p-button-text'>
              <i className='pi pi-compass mr-2' />
              Help Center
            </Link>
          </div>
        </div>

        {/* divider */}
        <div className='w-[2px] h-full bg-slate-200 ml-4' />

        <div className='flex flex-col basis-full h-full bg-primary-50 px-4 py-3 gap-2'>
          {/* view controls */}
          <div className='basis-0 bg-white rounded-md p-2 pb-0'>
            <ViewControls />
          </div>

          {/* properties list */}
          <div className='basis-full h-full overflow-auto'>
            <PropertiesTable />
          </div>
        </div>
      </div>
    </div>
  );
}
