import { Skeleton } from 'primereact/skeleton';

export default function TemplateListItemSkeleton() {
  return (
    <div className='flex justify-between items-center w-full h-[130px] rounded-none px-4 '>
      <div className='flex items-center gap-5 h-full'>
        <Skeleton shape='rectangle' height='100%' width='6.5rem' />
        <div className='flex flex-col gap-3 w-[14rem]'>
          <Skeleton width='100%' height='1.5rem' />
          <Skeleton width='80%' height='1.5rem' />
          <Skeleton width='25%' height='1.5rem' />
        </div>
      </div>
      <Skeleton shape='rectangle' height='45px' width='85px' />
    </div>
  );
}
