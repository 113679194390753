import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { authApi } from '../auth/authApi';

export type ObjectTypesSliceState = {
  objectTypeId: string | null; // objectTypeId or objectTypeName e.g. CONTACT, COMPANIES, 0-1, 0-2, etc.
};

const initialState: ObjectTypesSliceState = { objectTypeId: null };

export const objectTypesSlice = createSlice({
  name: 'objectTypes',
  initialState,
  reducers: {
    setObjectTypeId(state, action: PayloadAction<string | null>) {
      state.objectTypeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Updates the object type from the user login info.
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.objectTypeId = payload.objectType || null;
    });
  },
});

export const { setObjectTypeId } = objectTypesSlice.actions;

export const selectObjectTypeId = (state: RootState) => state.objectTypes.objectTypeId;

export default objectTypesSlice.reducer;
