import { useDispatch, useSelector } from 'react-redux';
import {
  resetDocumentData as _resetDocumentData,
  setDocumentUrl as _setDocumentUrl,
  PrevDocumentParams,
  resetPrevParams as _resetPrevParams,
  setPrevParams as _setPrevParams,
  selectDocumentUrl,
  selectPrevParams,
} from '../documentSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTemplates } from '../../templates';
import { useObjectTypes } from '../../objectTypes';
import useObjects from '../../objects/hooks/useObjects';

export interface CurrentDocumentParams {
  objectId: string;
  objectTypeId: string;
  templateId: string;
}

interface UseDocumentReturnType {
  documentUrl: string | undefined;
  prevParams: PrevDocumentParams | undefined;
  currentParams: CurrentDocumentParams | undefined;
  paramsChanged: boolean;

  setDocumentUrl: (url: string | undefined) => void;
  setPrevParams: (params: PrevDocumentParams) => void;
  resetPrevParams: () => void;

  resetDocumentData: () => void;
}

export function useDocument(): UseDocumentReturnType {
  const [paramsChanged, setParamsChanged] = useState<boolean>(false);

  const documentUrl = useSelector(selectDocumentUrl);
  const prevParams = useSelector(selectPrevParams);

  // Check if all params are present to generate the document and compare with the previous params
  const { objectId } = useObjects();
  const { templateId } = useTemplates();
  const { objectTypeId } = useObjectTypes();

  const dispatch = useDispatch();

  const setDocumentUrl = useCallback(
    (url: string | undefined) => {
      dispatch(_setDocumentUrl(url));
    },
    [dispatch],
  );

  const setPrevParams = useCallback(
    (params: PrevDocumentParams) => {
      dispatch(_setPrevParams(params));
    },
    [dispatch],
  );

  const resetPrevParams = useCallback(() => {
    dispatch(_resetPrevParams());
  }, [dispatch]);

  const resetDocumentData = useCallback(() => {
    dispatch(_resetDocumentData());
  }, [dispatch]);

  // Combines document params to track when they change and notify when there is not enough data to generate the document.
  const currentParams = useMemo(
    () =>
      objectId && templateId && objectTypeId
        ? {
            objectId,
            templateId,
            objectTypeId,
          }
        : undefined,
    [objectId, templateId, objectTypeId],
  );

  // Tracks when the params change to reset the document URL. Components should wait for paramsChanged to be true before refetching the document.
  useEffect(() => {
    if (!setParamsChanged || !setDocumentUrl) return;
    const { objectTypeId: prevObjectTypeId, objectId: prevObjectId, templateId: prevTemplateId } = prevParams || {};

    if (prevObjectTypeId === objectTypeId && prevObjectId === objectId && prevTemplateId === templateId) {
      setParamsChanged(false);
      return;
    } else {
      setParamsChanged(true);
      setDocumentUrl(undefined);
    }
  }, [objectId, templateId, objectTypeId, prevParams, setParamsChanged, setDocumentUrl]);

  return useMemo(
    () => ({
      documentUrl,
      prevParams,
      currentParams,
      paramsChanged,
      setDocumentUrl,
      setPrevParams,
      resetPrevParams,
      resetDocumentData,
    }),
    [
      documentUrl,
      prevParams,
      setDocumentUrl,
      setPrevParams,
      resetPrevParams,
      resetDocumentData,
      paramsChanged,
      currentParams,
    ],
  );
}
