import { useCallback, useMemo } from 'react';
import { CustomObjectType, useGetAllObjectTypesQuery } from '../objectTypesApi';
import { useDispatch, useSelector } from 'react-redux';
import { selectObjectTypeId } from '../objectTypesSlice';
import { setObjectTypeId as _setObjectTypeId } from '../objectTypesSlice';

interface UseObjectsReturnValue {
  selectedObjectType: CustomObjectType | null;
  objectTypeId: string | null;
  objectTypeList: CustomObjectType[] | undefined;
  setObjectTypeId: (objectType: string) => void;
}

export function useObjectTypes(): UseObjectsReturnValue {
  const { data: objectTypeList } = useGetAllObjectTypesQuery();
  const objectTypeId = useSelector(selectObjectTypeId);

  const dispatch = useDispatch();

  const setObjectTypeId = useCallback(
    (objectTypeId: string | null) => {
      dispatch(_setObjectTypeId(objectTypeId));
    },
    [dispatch],
  );

  const selectedObjectType = useMemo(() => {
    const selectedObjectTypeId = objectTypeId;
    return (
      objectTypeList?.find(
        ({ objectTypeId, objectTypeName }) =>
          objectTypeId === selectedObjectTypeId || objectTypeName === selectedObjectTypeId,
      ) || null
    );
  }, [objectTypeId, objectTypeList]);

  return {
    selectedObjectType,
    objectTypeId,
    objectTypeList,
    setObjectTypeId,
  };
}

export default useObjectTypes;
